.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  img {
    height: 64px;
    width: 64px;
  }
}
