.PostForm {
  display: grid;
  gap: 8px;
  grid-template-areas:
    "images right"
    "left   right";
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr auto;
}

.LeftColumn,
.RightColumn {
  display: flex;
  flex-direction: column;
}

.images {
  grid-area: images;
}

.LeftColumn {
  grid-area: left;
}

.RightColumn {
  grid-area: right;
}
