.image {
  position: relative;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 200px;

  img {
    max-width: 200px;
    max-height: 200px;

    object-fit: contain;
    object-position: center;
  }

  &.primary {
    width: 100%;
    height: auto;

    img {
      max-width: 100%;
      max-height: 400px;
    }
  }

  .imgWrapper {
    position: relative;
  }

  .actionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: auto;
    opacity: 0;
    transition: opacity 250ms;
  }

  &:hover {
    .actionsWrapper {
      opacity: 1;
    }
  }
}
