.postImage {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 4px;

  max-height: #{"min(100vh, 800px)"};
  width: 100%;

  user-select: none;

  &.isMobile {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-bottom: 4px;
    }
  }
}

.imgWrapper {
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 4px;
    flex-grow: 1;
    flex-shrink: 1;
    object-fit: contain;
    max-width: 100vw;
    max-height: #{"min(100vh, 800px)"};
    width: auto;
    aspect-ratio: 1 / 1.4142;
  }
}

.nav {
  display: grid;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  grid-template-rows: auto;

  grid-template-areas:
    "leftNav imageNav rightNav";

  &.mobile {
    grid-template-rows: auto auto;
    grid-template-areas:
      "leftNav rightNav"
      "imageNav imageNav";

    .imageNav { padding-top: 8px }
  }

  .nextPost { grid-area: leftNav }

  .prevPost { grid-area: rightNav }

  .imageNav { grid-area: imageNav }

  a {
    cursor: pointer;
  }
}
