.imgWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: #{"calc(100vh - 325px)"};
  overflow: hidden;

  user-select: none;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.nav {
  display: grid;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  grid-template-rows: auto;

  grid-template-areas:
    "leftNav imageNav rightNav";

  &.mobile {
    grid-template-rows: auto auto;
    grid-template-areas:
      "leftNav rightNav"
      "imageNav imageNav";

    .imageNav { padding-top: 8px }
  }

  .nextPost { grid-area: leftNav }

  .prevPost { grid-area: rightNav }

  .imageNav { grid-area: imageNav }

  a {
    cursor: pointer;
  }
}

.title {
  font-size: 20px;
}

.date {
  font-size: 14px;
}
